import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import './App.css';
import InitialLoadingComponent from './components/initial-loading/initial-loading.component';

const HomePage = lazy(() => import('./pages/home/home.component')); 
const StorePage = lazy(() => import('./pages/store/store.component'))
const ProductDetailPage = lazy(() => import('./pages/product-detail/product-detail.component'))
const ContactPage = lazy(() => import('./pages/contact/contact.component'))
const Header = lazy(() => import('./components/header/header.component'))
const Footer = lazy(() => import('./components/footer/footer.component'))
const ServicesPage = lazy(() => import('./pages/services/services.component'))
const AboutUsPage = lazy(() => import('./pages/about-us/about-us.component'))
const BlogPage = lazy(() => import('./pages/blog/blog.component'))
const BlogDetailPage = lazy(() => import('./pages/blog-detail/blog-detail.component'))
const PrivacyPolicyPage = lazy(() => import('./pages/privacy-policy/privacy-policy.component'))

function App() {
  const supportedLanguages = ['es', 'en'];
  return (
    <div className="App">
      {/* <InitialLoadingComponent /> */}
      <Suspense fallback={ <InitialLoadingComponent /> }>
        <Header />
        <Switch>
          <Route exact path="/:lang/store" component={ StorePage } />
          <Route exact path="/:lang/store/product/:productId" component={ ProductDetailPage } />
          <Route exact path="/:lang/contact" component={ ContactPage } />
          <Route exact path="/:lang/services" component={ ServicesPage } />
          <Route exact path="/:lang/about-us" component={ AboutUsPage } />
          <Route exact path="/:lang/blog" component={ BlogPage } />
          <Route exact path="/:lang/blog/:blogId" component={ BlogDetailPage } />
          <Route exact path="/:lang/privacy-policy" component={ PrivacyPolicyPage } />
          <Route
            exact
            path="/:lang"
            render={props =>
              supportedLanguages.includes(props.match.params.lang) ? (
                <HomePage {...props} />
              ) : (
                <Redirect to={`/es/${props.match.params.lang}`} />
              )
            } />
          <Redirect to="/es" />
        </Switch>
        <Footer></Footer>
      </Suspense>
    </div>
  );
}

export default App;
