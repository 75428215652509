import {
    responsiveMenuStatuses,
    TOGGLE_RESPONSIVE_MENU
} from '../actions/ui.actions.js';

const initialState = {
    responsiveMenuStatus: responsiveMenuStatuses.INACTIVE
}

function ui(state = initialState, action) {
    switch (action.type) {
        case TOGGLE_RESPONSIVE_MENU:
            return {
                ...state,
                responsiveMenuStatus: action.status
            }
        default:
            return state;
    }
}

export default ui;