export const TOGGLE_RESPONSIVE_MENU = 'TOGGLE_RESPONSIVE_MENU';

export const responsiveMenuStatuses = {
    ACTIVE: true,
    INACTIVE: false
}

export function toggleResponsiveMenu(status) {
    return {
        type: TOGGLE_RESPONSIVE_MENU,
        status
    }
}